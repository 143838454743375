import React from 'react';

const Coder = () => {
  return (
    <div className="main">
      <h2>Staň se kodérkou</h2>
      <h3>Knížky</h3>
      <ul>
        <li>UX pro začátečníky</li>
        <li>Dobrý designér to všechno ví!</li>
        <li>Web ostrý jako břitva</li>

        <li>Web copywriting pro samouky</li>
        <li>Copywriting Pište texty, které prodávají</li>
        <li>
          Sprint jak vyřešit velké problémy a otestovat nové myšlenky v pouhých
          pěti dnech
        </li>
        <li>Miniknížky Austin Kleon</li>
        <li>Zbraně vlivu</li>
      </ul>
      <h3>Barvičky</h3>
      <ul>
        <li>
          <a href="https://coolors.co/" target="_blank" rel="noreferrer">
            coolors.co
          </a>
        </li>
        <li>
          <a
            href="https://color.adobe.com/cs/create/color-wheel"
            target="_blank"
            rel="noreferrer"
          >
            Barvičky od adobe
          </a>
        </li>
        <li>
          <a
            href="https://paletton.com/#uid=1000u0kllllaFw0g0qFqFg0w0aF"
            target="_blank"
            rel="noreferrer"
          >
            Paletton
          </a>
        </li>
        <li>
          <a
            href="https://htmlcolorcodes.com/"
            target="_blank"
            rel="noreferrer"
          >
            HTML COLOR CODES
          </a>
        </li>
        <li>
          <a
            href="https://www.w3schools.com/cssref/css_colors.asp"
            target="_blank"
            rel="noreferrer"
          >
            Pojmenované barvy
          </a>{' '}
          nebo{' '}
          <a
            href="https://developer.mozilla.org/en-US/docs/Web/CSS/color_value"
            target="_blank"
            rel="noreferrer"
          >
            tady.
          </a>
        </li>
      </ul>
      <h3>Kontrast</h3>
      <ul>
        <li>
          <a
            href="https://contrast-ratio.com/"
            target="_blank"
            rel="noreferrer"
          >
            Contrast ratio
          </a>
        </li>
        <li>
          <a href="https://colourcontrast.cc/" target="_blank" rel="noreferrer">
            Colour Contrast Checker
          </a>
        </li>
        <li>
          <a
            href="https://contrastchecker.com/"
            target="_blank"
            rel="noreferrer"
          >
            CONTRAST CHECKER
          </a>
        </li>
        <li>
          <a
            href="https://webaim.org/resources/contrastchecker/"
            target="_blank"
            rel="noreferrer"
          >
            WebAIM
          </a>
        </li>
        <li>
          <a
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.accessibility.auditor&hl=en"
            target="_blank"
            rel="noreferrer"
          >
            Aplikace Accessibility Scanner
          </a>
        </li>
        <li>
          <a
            href="http://www.color-blindness.com/coblis-color-blindness-simulator/"
            target="_blank"
            rel="noreferrer"
          >
            Color Blindness Simulator
          </a>{' '}
          Simulátor barevné slepoty
        </li>
      </ul>
      <h3>Inspirace</h3>
      <ul>
        <li>
          <a href="https://www.behance.net/" target="_blank" rel="noreferrer">
            Behence
          </a>
        </li>
        <li>
          <a href="https://dribbble.com/" target="_blank" rel="noreferrer">
            Dribbble
          </a>{' '}
          Pozor návrhy zde nejsou otestované. Pouze design inspirace.
        </li>
        <li>
          <a href="https://www.behance.net/" target="_blank" rel="noreferrer">
            Behence
          </a>
        </li>
        <li>
          <a
            href="https://www.deviantart.com/"
            target="_blank"
            rel="noreferrer"
          >
            Deviant art
          </a>
        </li>
        <li>
          <a
            href="http://www.jinudy.cz/clanky/psychologie-barev/"
            target="_blank"
            rel="noreferrer"
          >
            Psychologie barev
          </a>
        </li>
      </ul>
      <h3>Design systems</h3>
      <ul>
        <li>
          <a
            href="https://material.io/resources"
            target="_blank"
            rel="noreferrer"
          >
            MATERIAL DESIGN
          </a>
        </li>
        <li>
          <a
            href="https://polaris.shopify.com/content/voice-and-tone"
            target="_blank"
            rel="noreferrer"
          >
            Polaris (Shopify)
          </a>
        </li>
        <li>
          <a
            href="https://airbnb.design/building-a-visual-language/"
            target="_blank"
            rel="noreferrer"
          >
            Airbnb
          </a>
        </li>
        <li>
          <a
            href="https://developer.apple.com/design/"
            target="_blank"
            rel="noreferrer"
          >
            Apple
          </a>
        </li>
        <li>
          <a
            href="https://www.microsoft.com/design/fluent/#/"
            target="_blank"
            rel="noreferrer"
          >
            Microsoft Design
          </a>
        </li>

        <li>
          <a
            href="https://www.carbondesignsystem.com/"
            target="_blank"
            rel="noreferrer"
          >
            Carbon Design System Search (IBM)
          </a>
        </li>
        <li>
          <a
            href="https://orbit.kiwi/components/"
            target="_blank"
            rel="noreferrer"
          >
            Orbit (Kiwi)
          </a>
        </li>
        <li>
          <a
            href="https://designsystem.digital.gov/"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            U.S. Web Design System (USWDS)
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Coder;
