import React from 'react';
import {Link} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';

import Skills from '../Skills/Skills';
import Instagram from '../Instagram/Instagram';

const Home = () => {
  function ErrorFallback({error, resetErrorBoundary}) {
    return (
        <div role="alert">
          <p>Něco nefunguje</p>
          <pre>{error.message}</pre>
          <button onClick={resetErrorBoundary}>Načíst instagram</button>
        </div>
    );
  }

  return (
      <>
        <div className="main">
          <p>Ahoj jsem Slávka,</p>
          <p>začínající webová vývojářka. Zajímají mě různé oblasti webového vývoje, a proto se věnuji neustálému učení
            a zdokonalování svých dovedností. Věřím, že kreativita, nadšení a pečlivost jsou základem pro tvorbu
            kvalitních a uživatelsky přívětivých webových řešení.</p>
          <p>Kromě webového vývoje jsem také Senior Koučka v Czechitas, kde se podílím na vzdělávacích projektech pro
            ženy a dívky v oblasti IT. Mám velkou radost z toho, že mohu být součástí iniciativy, která pomáhá zvyšovat
            zastoupení žen v IT</p><h2>Kdy můžu s webem pomoct:</h2>
          <p>
            Pokud potřebujete <strong>webovou stránku malého rozsahu</strong>, která bude plnit Vaše specifické
            požadavky, jste na správném místě.
          </p>
          <p>
            I když se nevěnuji tvorbě projektů již 10 let a nemám na kontě 1000 dokonalých projektů a miliony
            spokojených zákazníků, budu se snažit Vám nabídnout své zkušenosti a znalosti, aby Vaše webová stránka
            byla <strong>plně funkční, moderní a vizuálně přitažlivá</strong>.
          </p>
          <p>
            Máte-li představu o tom, jak by Vaše webová stránka měla vypadat, budu ráda, když mi ji sdělíte. Samozřejmě,
            nebude se jednat o kopírování jiných webových stránek, ale spíše o inspiraci pro tvorbu unikátního řešení,
            které bude <strong>splňovat Vaše potřeby a očekávání</strong>.
          </p>
          <p>
            Budu potřebovat, abyste mi dodali fotografie a texty, které by měly být na webové stránce zahrnuty. Pokud
            budete mít jakékoliv dotazy nebo specifické požadavky, neváhejte mě kontaktovat. Samozřejmě, budu si s Vámi
            v průběhu tvorby webové stránky průběžně konzultovat, aby byl <strong>výsledek co nejblíže Vašim
            představám</strong>.
          </p>
          <p>Kontaktujte mě a ráda s Vámi proberu Vaše potřeby a pomohu Vám vytvořit úspěšnou webovou přítomnost.</p>
          <p> Děkuji za Vaši důvěru a těším se na spolupráci s Vámi!</p>
          <div className="button__row">
            <Link to="/kontakt">
              <button className="button">CHCI WEBOVKY</button>
            </Link>
          </div>

          <h2>Ceník?</h2>
          <p className="center">Nejprve bychom se měli zaměřit na to, co přesně potřebujete a jak mohu pomoci.</p>

          <h2>Co už umím:</h2>
          <ul>
            <li>
              Tvorba obsahu a stylování komplexních webových stránek pomocí <strong>HTML</strong> a <strong>CSS</strong>.
            </li>

            <li>
              Pokročilé techniky pro tvorbu layoutu stránky (
              <strong>flexbox</strong> a <strong>CSS grid</strong>).
            </li>
            <li>Zkušenosti s Sass a Bootstrap.
            </li>
            <li>
              Programování v jazyce {" "}
              <strong>JavaScript</strong>.
            </li>
            <li>
              Znalosti principů komponentového návrhu aplikací a základní ovládání knihoven{" "}
              <strong>React, Next.js a Node.js</strong>.
            </li>
            <li>
              Práce s verzovacím systémem{" "}
              <strong>Git a GitHub, GitLab, Webpack, npm</strong>.
            </li>
            <li>
              Programování v jazyce PHP{" "}
              <strong>OOP, Nette</strong>.
            </li>
            <li>
              Základy <strong>SEO</strong>.
            </li>
            <li>
              Základy designu uživatelské zkušenosti (<strong>UX</strong>) a
              procesu návrhu.
            </li>
            <li>
              VSchopnost vytvořit Wireframes, <strong>prototypy</strong> i High-Fidelity Design v
              nástrojích <strong> Figma a Adobe XD</strong>.
            </li>
            <li>Zkušenosti s responzivním web designem.</li>
          </ul>
          <Skills/>
          {/*  <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
        >
          <Instagram />
        </ErrorBoundary>*/}
        </div>
      </>
  );
};

export default Home;
